import * as yup from "yup";
const phoneRules = "[+]{1}[0-9]{9,14}";
// const urlRules = /^(www\.|https?:\/\/)/;
const urlRules = /^(https?:\/\/).*/;
const emailRules =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const addMemberSchema = yup.object().shape({
  // companyProfile: yup.string().required("Company Name is required"),
  companyName: yup.string().max(254, "Content is too large").required("Company Name is required"),
  unitNo: yup.string().max(254, "Content is too large").required("Street is required"),
  // street: yup.string().required("Street is required"),
  memberType: yup.string().nullable().trim().required("Member Type is required"),
  memberFee: yup
    .number()
    .typeError("Member Fee must be a number")
    .required("Member Fee is required"),
  country: yup.string().required("Country is required"),
  // floorNo: yup.string().required("Floor Number is required"),
  // block: yup.string().required("Block Number is required"),
  postcode: yup.string().max(254, "Content is too large").required("Postal code is required"),
  // province: yup.string().required("State is required"),
  city: yup.string().max(254, "Content is too large").required("City is required"),
  roles: yup.array().min(1, "User role is required "),
  // totalImport: yup
  //   .number()
  //   .max(100, "Total Import % is too large")
  //   .typeError("Total Import % must be a number")
  //   .required("Total Import % is required"),
  // totalExport: yup
  //   .number()
  //   .max(100, "Total Export % is too large")
  //   .typeError("Total Export % must be a number")
  //   .required("Total Export % is required"),
  // services: yup.array().min(1, "Services are required "),
  // carriers: yup.array().min(1, "Top Carriers Used are required "),
  // airlines: yup.array().min(1, "Top Airlines Used are required "),
  // exportCountries: yup.array().min(1, "Top Countries Exporting To are required "),
  // importCountries: yup.array().min(1, "Top Countries Importing From are required "),
  //marketsToDevelop: yup.array().min(1, "Markets Looking To Develop In are required "),
  bannerTitle: yup.string().max(499, "Content is too large").nullable().trim(),
  bannerBody: yup.string().max(7999, "Content is too large").nullable().trim(),
  marketsToDevelop: yup.array(),
  marketsToDevelopCommaSeparated: yup.string(),
  lat: yup
    .number()
    .max(254, "Content is too large")
    .typeError("Latitude must be a number")
    .required("Latitude is required"),
  long: yup
    .number()
    .max(254, "Content is too large")
    .typeError("Longitude must be a number")
    .required("Longitude is required"),
  websiteUrl: yup
    .string()
    .max(254, "Content is too large")
    .matches(urlRules, "Enter correct url with 'https://'")
    .required("Website URL is required"),
  linkedInUrl: yup
    .string()
    .max(254, "Content is too large")
    .matches(urlRules, "Enter correct url with 'https://'")
    .required("Linkedin URL is required"),
  email: yup
    .string()
    .max(254, "Content is too large")
    .matches(emailRules, "Please enter valid Email.")
    .required("Company Email is required"),
  officeContactNumber: yup
    .string()
    .max(254, "Content is too large")
    .matches(phoneRules, { message: "Please enter valid Phone number" })
    .required("Phone number is required"),
  approvedTimestamp: yup.string().required("Enrollment Date is required"),
  expiryDate: yup.string().required("Expiry Date is required"),
  // email: yup.string().email("Please enter valid Email.").required("Email is required"),
  file: yup
    .mixed()
    .required("Company Logo is required")
    .test("fileSize", "Please upload file below 5MB.", (value) => {
      if (value) {
        return value.size <= 5000000;
      }
      return true;
    }),
});
